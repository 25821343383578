/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/summernote@0.8.20/dist/summernote-bs4.min.js
 * - /npm/summernote@0.8.20/dist/lang/summernote-en-US.min.js
 * - /npm/summernote@0.8.20/dist/lang/summernote-es-ES.min.js
 * - /npm/summernote@0.8.20/dist/lang/summernote-fr-FR.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
